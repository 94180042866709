import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from "gatsby"
import ProjectListIndexItem from '../projects/list-index-item'
import { useDetectClickOutside } from 'react-detect-click-outside';
import Helmet from 'react-helmet';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const index = (data) => {
    return (
        <>
            {data.prismicHome.data.project_index.map((article, i) => {
                if (article) {
                    return (
                        <ProjectListIndexItem key={i} article={article.project_index_project.document} />
                    )
                }
                else {
                    return null
                }
            })}
        </>
    )
}

export const Clients = ({ cssClass, moreWork, data }) => {
    const [indexActive, setIndexActive] = useState(false);

    const handleIndexToggle = () => {
        setIndexActive(!indexActive);
    }

    const closeIndex = () => {
        setIndexActive(false);
    }

    const ref = useDetectClickOutside({ onTriggered: closeIndex });

    gsap.registerPlugin(ScrollTrigger);
    useEffect(() => {
        gsap.utils.toArray(".c-clients__list div").forEach(function (section, i) {
            ScrollTrigger.create({
                toggleClass: "is-visible",
                trigger: section,
                //markers: true,
                start: "top center",
                end: "bottom center"
            });
        });
      }, []);

    return (
        <>
            {moreWork ? 
                <Helmet>
                    {indexActive ? 
                    <body class="is-project-index"></body>
                    : null}
                </Helmet>
            : null}
            <div className={`c-clients ${indexActive ? 'is-index' : ''} ${cssClass ? cssClass : ''}`}>
                <div className="c-clients__wrapper" ref={ref}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="c-clients__header">
                                <h2 className="c-clients__header-title">Clients</h2>
                                {moreWork ?
                                    <button className="c-clients__header-toggle" onClick={handleIndexToggle}> More Work</button>
                                : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="c-clients__list">
                                {data.map((client, i) => {
                                    return (
                                        <div key={i}>
                                            <span>{client.client_name.text}</span>
                                            {client.client_video.url ? 
                                                <video autoPlay muted loop playsInline src={`${client.client_video.url}#t=0.1`} loading="lazy" className={client.client_video_orientation === 'Portrait' ? 'is-portrait' : ''}></video>
                                            :
                                                <img srcSet={`
                                                    ${client.client_image.url}&w=800&fit=max&dpr=1 1x,
                                                    ${client.client_image.url}&w=800&fit=max&dpr=2 2x
                                                `}
                                                alt="" loading="lazy" width={client.client_image.dimensions.width} height={client.client_image.dimensions.height} className={client.client_image.dimensions.height > client.client_image.dimensions.width ? 'is-portrait' : ''} />
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {moreWork ?
                    <div className="c-project-listing__index">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="c-project-listing__index-header">
                                    <div className="c-project-listing__index-header-title">Work Index <svg fill="none" viewBox="0 0 17 22"><path d="m8.298 21.2 8.1-7.5-1.2-1.32-6.03 5.61V.47h-1.74v17.52l-6-5.61-1.23 1.32 8.1 7.5Z" fill="#0C0C0C" /></svg></div>
                                    <button className="c-project-listing__index-header-close" onClick={handleIndexToggle}>Close</button>
                                </div>
                            </div>
                            <div className="row">
                                <StaticQuery
                                    query={graphql`
                                        query {
                                            prismicHome {
                                                data {
                                                  project_index {
                                                    project_index_project {
                                                      document {
                                                        ... on PrismicProject {
                                                          id
                                                          data {
                                                            image {
                                                              alt
                                                              url
                                                              dimensions {
                                                                height
                                                                width
                                                              }
                                                            }
                                                            image2 {
                                                              alt
                                                              url
                                                              dimensions {
                                                                height
                                                                width
                                                              }
                                                            }
                                                            index_image {
                                                                alt
                                                                url
                                                                dimensions {
                                                                  height
                                                                  width
                                                                }
                                                              }
                                                              index_image2 {
                                                                alt
                                                                url
                                                                dimensions {
                                                                  height
                                                                  width
                                                                }
                                                              }
                                                            summary {
                                                              text
                                                            }
                                                            title {
                                                              text
                                                            }
                                                          }
                                                          url
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                        }
                                    `}
                                render={data => index(data)} />
                            </div>
                            <div className="row">
                                <div className="c-footer__legal">
                                    <div className="c-footer__copyright">&copy; Two Create Studio 2022</div>
                                    <ul className="c-footer__legal-items">
                                        <li className="c-footer__legal-item"><a href="https://www.instagram.com/two_create_studio/" className="c-footer__legal-item-link" rel="noopener">Instagram</a></li>
                                        <li className="c-footer__legal-item"><a href="mailto:studio@twocreate.co.uk" className="c-footer__legal-item-link">studio@twocreate.co.uk</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                </div>
            </div>
        </>
    )
}

export default Clients